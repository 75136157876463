
import ContactForm from '../components/contact/ContactForm'
import ContactIntro from '../components/contact/ContactIntro'
const Contact = () => {
  return (
   <>
   <ContactIntro />


   <ContactForm />
    
   </>
  )
}

export default Contact